
[data-theme-color="color_1"]{
	--primary: #a3cc02;
	--secondary: #323232;
	--primary-hover: #8eb200;
	--primary-dark: #779500;
	
	--rgba-primary-1: rgba(163,204,2,0.1);
	--rgba-primary-2: rgba(163,204,2,0.2);
	--rgba-primary-3: rgba(163,204,2,0.3);
	--rgba-primary-4: rgba(163,204,2,0.4);
	--rgba-primary-5: rgba(163,204,2,0.5);
	--rgba-primary-6: rgba(163,204,2,0.6);
	--rgba-primary-7: rgba(163,204,2,0.7);
	--rgba-primary-8: rgba(163,204,2,0.8);
	--rgba-primary-9: rgba(163,204,2,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}

[data-theme-color="color_2"]{
	--primary: #57b7c0;
	--secondary: #212529;
	--primary-hover: #47a4ad;
	--primary-dark: #12757e;
	
	--rgba-primary-1: rgba(87,183,192,0.1);
	--rgba-primary-2: rgba(87,183,192,0.2);
	--rgba-primary-3: rgba(87,183,192,0.3);
	--rgba-primary-4: rgba(87,183,192,0.4);
	--rgba-primary-5: rgba(87,183,192,0.5);
	--rgba-primary-6: rgba(87,183,192,0.6);
	--rgba-primary-7: rgba(87,183,192,0.7);
	--rgba-primary-8: rgba(87,183,192,0.8);
	--rgba-primary-9: rgba(87,183,192,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}

[data-theme-color="color_3"]{
	--primary:#c6a47e;
	--secondary:#212529;
	--primary-hover:#b68b5b;
	--primary-dark:#775734;
	
	--rgba-primary-1:rgba(198,164,126,0.1);
	--rgba-primary-2:rgba(198,164,126,0.2);
	--rgba-primary-3:rgba(198,164,126,0.3);
	--rgba-primary-4:rgba(198,164,126,0.4);
	--rgba-primary-5:rgba(198,164,126,0.5);
	--rgba-primary-6:rgba(198,164,126,0.6);
	--rgba-primary-7:rgba(198,164,126,0.7);
	--rgba-primary-8:rgba(198,164,126,0.8);
	--rgba-primary-9:rgba(198,164,126,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}

[data-theme-color="color_4"]{
	--primary: #ff1d4d;
	--secondary: #212529;
	--primary-hover: #e30736;
	--primary-dark: #a60728;
	
	--rgba-primary-1: rgba(166,7,40,0.1);
	--rgba-primary-2: rgba(166,7,40,0.2);
	--rgba-primary-3: rgba(166,7,40,0.3);
	--rgba-primary-4: rgba(166,7,40,0.4);
	--rgba-primary-5: rgba(166,7,40,0.5);
	--rgba-primary-6: rgba(166,7,40,0.6);
	--rgba-primary-7: rgba(166,7,40,0.7);
	--rgba-primary-8: rgba(166,7,40,0.8);
	--rgba-primary-9: rgba(166,7,40,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}

[data-theme-color="color_5"]{
	--primary: #eab248;
	--secondary: #212529;
	--primary-hover: #c89638;
	--primary-dark: #a6720e;
	
	--rgba-primary-1: rgba(234,178,72,0.1);
	--rgba-primary-2: rgba(234,178,72,0.2);
	--rgba-primary-3: rgba(234,178,72,0.3);
	--rgba-primary-4: rgba(234,178,72,0.4);
	--rgba-primary-5: rgba(234,178,72,0.5);
	--rgba-primary-6: rgba(234,178,72,0.6);
	--rgba-primary-7: rgba(234,178,72,0.7);
	--rgba-primary-8: rgba(234,178,72,0.8);
	--rgba-primary-9: rgba(234,178,72,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}
[data-theme-color="color_6"]{
	--primary: #ef9f7e;
	--secondary: #212529;
	--primary-hover: #db8a69;
	--primary-dark: #b25d3b;
	
	--rgba-primary-1: rgba(239,159,126,0.1);
	--rgba-primary-2: rgba(239,159,126,0.2);
	--rgba-primary-3: rgba(239,159,126,0.3);
	--rgba-primary-4: rgba(239,159,126,0.4);
	--rgba-primary-5: rgba(239,159,126,0.5);
	--rgba-primary-6: rgba(239,159,126,0.6);
	--rgba-primary-7: rgba(239,159,126,0.7);
	--rgba-primary-8: rgba(239,159,126,0.8);
	--rgba-primary-9: rgba(239,159,126,0.9);
	--title: #212529;
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}
[data-theme-color="color_7"]{
    --primary: #e485da;
	--secondary: #212529;
	--primary-hover: #d070c6;
	--primary-dark: #9c4292;
	
	--rgba-primary-1: rgba(228,133,218,0.1);
	--rgba-primary-2: rgba(228,133,218,0.2);
	--rgba-primary-3: rgba(228,133,218,0.3);
	--rgba-primary-4: rgba(228,133,218,0.4);
	--rgba-primary-5: rgba(228,133,218,0.5);
	--rgba-primary-6: rgba(228,133,218,0.6);
	--rgba-primary-7: rgba(228,133,218,0.7);
	--rgba-primary-8: rgba(228,133,218,0.8);
	--rgba-primary-9: rgba(228,133,218,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}
[data-theme-color="color_8"]{
	--primary: #8669d4;
	--secondary: #212529;
	--primary-hover: #7559be;
	--primary-dark: #533a97;
	
	--rgba-primary-1: rgba(134,105,212,0.1);
	--rgba-primary-2: rgba(134,105,212,0.2);
	--rgba-primary-3: rgba(134,105,212,0.3);
	--rgba-primary-4: rgba(134,105,212,0.4);
	--rgba-primary-5: rgba(134,105,212,0.5);
	--rgba-primary-6: rgba(134,105,212,0.6);
	--rgba-primary-7: rgba(134,105,212,0.7);
	--rgba-primary-8: rgba(134,105,212,0.8);
	--rgba-primary-9: rgba(134,105,212,0.9);

	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}


[data-primary="color-skin-1"]{
	--primary: #a3cc02;
	--secondary: #323232;
	--primary-hover: #8eb200;
	--primary-dark: #779500;
	
	--rgba-primary-1: rgba(163,204,2,0.1);
	--rgba-primary-2: rgba(163,204,2,0.2);
	--rgba-primary-3: rgba(163,204,2,0.3);
	--rgba-primary-4: rgba(163,204,2,0.4);
	--rgba-primary-5: rgba(163,204,2,0.5);
	--rgba-primary-6: rgba(163,204,2,0.6);
	--rgba-primary-7: rgba(163,204,2,0.7);
	--rgba-primary-8: rgba(163,204,2,0.8);
	--rgba-primary-9: rgba(163,204,2,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}
[data-primary="color-skin-2"]{
	--primary: #57b7c0;
	--secondary: #212529;
	--primary-hover: #47a4ad;
	--primary-dark: #12757e;
	
	--rgba-primary-1: rgba(87,183,192,0.1);
	--rgba-primary-2: rgba(87,183,192,0.2);
	--rgba-primary-3: rgba(87,183,192,0.3);
	--rgba-primary-4: rgba(87,183,192,0.4);
	--rgba-primary-5: rgba(87,183,192,0.5);
	--rgba-primary-6: rgba(87,183,192,0.6);
	--rgba-primary-7: rgba(87,183,192,0.7);
	--rgba-primary-8: rgba(87,183,192,0.8);
	--rgba-primary-9: rgba(87,183,192,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}
[data-primary="color-skin-3"]{
	--primary:#c6a47e;
	--secondary:#212529;
	--primary-hover:#b68b5b;
	--primary-dark:#775734;
	
	--rgba-primary-1:rgba(198,164,126,0.1);
	--rgba-primary-2:rgba(198,164,126,0.2);
	--rgba-primary-3:rgba(198,164,126,0.3);
	--rgba-primary-4:rgba(198,164,126,0.4);
	--rgba-primary-5:rgba(198,164,126,0.5);
	--rgba-primary-6:rgba(198,164,126,0.6);
	--rgba-primary-7:rgba(198,164,126,0.7);
	--rgba-primary-8:rgba(198,164,126,0.8);
	--rgba-primary-9:rgba(198,164,126,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
}
