nav{
	.pagination{
		li{
			margin: 0 5px;
			.page-numbers,
			.page-link{
				height: 45px;
				width: 45px;
				line-height: 42px;
				border-radius: 0 !important;
				color: #777777;
				text-align: center;
				border: 0;
				padding: 0;
				transition: all 0.5s;
				border: 1px solid;
				border-color: #777777;
				font-size: 18px;
				
				&.current,
				&.active,
				&:hover,
				&:active,
				&:focus{
					color: $white;
					background-color: var(--primary);
					border-color: var(--primary);
					box-shadow: 0px 5px 12px var(--rgba-primary-4);
				}
			}
			.prev,
			.next{
				border-color: transparent;
				font-size: 24px;
			}
		}
		&.text-center{
			justify-content: center;
		}
		&.rounded-0{
			.page-item{
				.page-link{
					border-radius: 0;
				}
			}
		}				
	}
}