.site-footer{
	&.style-1{
		background-image:url(../images/background/bg2-1.png);
		background-size: contain;
		
		.footer-title{
			&:after{
				content: "";
				position: absolute;
				background-color: var(--primary);
				left: 0;
				height: 1px;
				width: 45px;
				bottom: 0;
			}
		}
		.footer-bottom{
			padding: 20px 0;
			background-color:#212121;
		}
		.footer-logo{
			a{
				display: inline-block;
				width: 160px;
			}	
		}
		.footer-top {
			padding: 100px 40px 70px;
			@include respond('tab-port'){
				padding: 50px 0px 20px;
			}
		}
		.social-list.style-1{
			margin-top:60px;
			
			@include respond('tab-land'){
				margin-top: 30px;
			}
		}
	}
	&.style-2{
		background-image:url(../images/background/bg3.png);
		background-size: cover;
		position: relative;
		z-index:1;
	
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #212529;
			opacity: 0.9;
			z-index:-1;
		}
		.footer-title{
			margin-bottom:15px;
			&:after{
				content: "";
				position: absolute;
				background-color: var(--primary);
				left: 0;
				height: 1px;
				width: 45px;
				bottom: 0;
			}
		}
		ul{
			li{
			padding-left:0;	
				a{
					&:before{
						display:none;
					}
				}
				svg{
				margin-right:10px;
				width:30px;
				}
			}
		}
		.footer-bottom{
			padding: 20px 0;
			background-color:#212121;
			.footer-link{
				li{
					&:before{
						display:none;
					}
				}
			}
		}
		.footer-logo{
			a{
				display: inline-block;
				width: 160px;
			}	
		}
		.footer-top {
			padding-top: 100px;
			padding-bottom: 60px;
			
			@include respond('tab-port'){
				padding-top: 50px;
				padding-bottom: 20px;
			}
		}
		.social-list.style-1{
			margin-top:60px;
		}
		
		
	}
}
.social-list.style-1{
	li{
		display: inline-block;
		margin-right: 8px;
		a {
			height: 40px;
			width: 40px;
			line-height: 40px;
			text-align: center;
			color: #fff;
			background-color: var(--primary);
			display: block;
			border-radius: 40px;
			&:hover{
				background-color: var(--primary-hover);
			}
		}
	}
}