.dz-team{
	// Style 1
	&.style-1{
		text-align: center;
		margin-top: 150px;
		    border-radius: 0;
		box-shadow: 0px 10px 25px rgba(0, 0, 0,  0.10);
		background: #fff;
		border: 2px solid var(--primary);
		.card-media{
			overflow: hidden;
			margin: -150px 30px 0;
			@include transitionSlow;
		}
		.card-body{
			padding-bottom: 10;
			.dz-position{
				font-size: 14px;
				color:var(--primary);
				font-weight:500;
			}
			p{
				margin: 10px 0 20px;
			}
			.dz-social {
				margin: -20px -30px -20px -30px;
				padding: 20px 20px;
				border-top: 2px solid var(--primary);
				margin-top: 20px;
			
			}
		}
		// Dz Social
		.dz-social {
			display: flex;
			justify-content: space-between;
			li {
				a{
					color: lighten($dark,30);
					@include transitionSlow;
					display: block;
					font-size: 18px;
					&:hover {
						color:var(--primary);
						transform: scale(1.5);
						display: block;
					}
				}
			}
		}
		&:hover {
			.card-media{
				box-shadow: 0px 15px 50px rgba(0, 0, 0,  0.10);
				transform:translateY(-10px);
			}
		}
		
	}
	// Style 2
	&.style-2{
		margin-top: 15px;
		margin-bottom: 15px;
		
		.dz-content{
			box-shadow: 0px 5px 10px rgba(31, 66, 135, 0.1);
			padding: 25px;
			z-index: 99;
			background-color: $white;
			margin: -50px 30px 0;
		}
		.dz-name{
			margin-bottom: 10px;
		}
		.dz-position{
			font-size: 15px;
			font-weight: 400;
			margin: 0;
		}
		.team-social{
			position: absolute;
			right: 30px;
			top: -25px;
			.share{
				display: block;
				height: 45px;
				width: 45px;
				line-height: 45px;
				border-radius: 10px;
				color: $white;					
				background-color: var(--primary);
				box-shadow: 0px 5px 10px rgba(31, 66, 135, 0.1);
				z-index: 1;
				position: relative;
			}
			ul{
				position: absolute;
				bottom: 0;
				left: 0;
				li{
					display: block;
					margin-top: -45px;
					@include transitionMedium;
					opacity: 0;
					a{
						height: 45px;
						width: 45px;
						line-height: 45px;
						border-radius: 10px;
						color: $white;					
						background-color: var(--primary);
						box-shadow: 0px 5px 10px rgba(31, 66, 135, 0.1);
						
					}
				}
			}
			&:hover{
				ul{
					bottom: 55px;
					li{
						margin-top: 10px;
						opacity: 1;
					}
				}
			}
		}
		@include respond('phone'){
			
		}
	}
	// Style 2
	&.style-3{
		.dz-position{
			font-family: $font-family-title;
			font-weight:300;
			text-transform:uppercase;
			letter-spacing:3px;
			font-size: 15px;
			&.line{
				&:before{
					content:"";
					position:relative;
					height:1px;
					top: -5px;
					margin-left: -50px;
					margin-right: 10px;
					display:inline-block;
					width:40px;
					background:var(--primary);
				}
			}
		}
		.dz-media{
			position:relative;
			overflow:hidden;
			.team-social{
				position:absolute;
				left: 50%;
				bottom:-50px;
				display:flex;
				transform: translateX(-50%);
				@include transitionMedium;
				
				li{
					display:block;
					margin-left: 5px;
					margin-right: 5px;
					a{
						background:var(--primary);
						width:45px;	
						height:45px;	
						line-height:45px;
						text-align:center;
						@include transitionMedium;
						display: block;
						color: #fff;
						
						&:hover{
								background:var(--primary);
						}
					}	
				}	
			}	
			
		}
		&:hover .dz-media{
			.team-social{
				bottom:20px;
			}
		}
	}
	
	// Style 4
	&.style-4{
		margin-top: 15px;
		margin-bottom: 15px;
		position: relative;
		@include transitionMedium;
		.dz-content{
			box-shadow: 0px 5px 10px rgba(31, 66, 135, 0.1);
			padding: 15px;
			z-index: 99;
			background-color: $white;
			margin: 0 15px 0 15px;
			position: absolute;
			bottom: 0;
			transform:translateY(50%);
			width: calc( 100% - 30px);
			overflow: hidden;
			height: 80px;
			@include transitionMedium;
			.team-social{
				display:flex;
				text-align:center;
				justify-content:center;
				align-items:center;
				@include transitionMedium;
				li{
			
					a{
						width:40px;
						height:40px;
						line-height:40px;
						display:block;
						@include transitionMedium;
						margin: 0 5px;
					}
					&:hover{
						a{
						background:var(--primary);
						i{
							color:#fff;
						}
						}
					}
				}
			}	
		}
		.dz-name{
			margin-bottom: 10px;
		}
		.dz-position{
			font-size: 15px;
			font-weight: 400;
			margin: 0;
		}
		
		&:hover .dz-content{
			height: 120px;
			transform: translateY(43px);
		}
		
	}
	
	
}
.team-section-1{
	&>div{
		&:nth-child(2){
			margin-top: 50px;
		}
		&:nth-child(3){
			margin-top: 100px;
		}
		&:nth-child(4){
			margin-top: 150px;
		}
		&:nth-child(5){
			margin-top: 200px;
		}
	}
}
.swiper-team{
	.mt-5{
		margin-top:0 !important;	
	}
}