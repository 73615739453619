footer{
	color:#fff;
	font-size:15px;
	background: #323232;
	background-image:url();
	
	
	.widget{
		margin-bottom:30px;
	}	
	strong{
		color:#fff;
	}	
	.footer-logo{
		margin-bottom:25px;
		img{
			max-width:100%;
		}
	}
	.widget-logo ul{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		
		li{
			display:inline-block;
		    flex: 50%;	
		    padding: 0 15px 15px 0px;	
		}
	}
	.widget_about{
		p{
			margin-bottom:20px;
			line-height: 24px;
		}
	}
	
	.footer-title{
		margin: 0;
		font-weight: 700;
		padding-bottom: 15px;
		margin-bottom: 30px;
		position: relative;
		color: $white;
		line-height: 1.2;
		
		.dz-separator{
			position:absolute;
			bottom:0;
			left:0;
		}
	}
	.widget:hover{
		.footer-title{
			span{
				&:after{
					width: 5px;
				}
				&:before {
					width: 55px;
				}
			}
		}
	}
	.footer-top{
		
		padding: 70px 0 20px;
		@include respond('phone-land'){
			padding: 50px 0 0;
		}
	}
	.footer-bottom{
		background-color: #36404a;
		font-size: 15px;
		padding: 15px 0;
		.copyright-text{
			a{
				color: $white;
				font-weight: 500;
			}
		}
		@include respond('tab-port'){
			text-align: center!important;
			.text-left,
			.text-right{
				text-align: center!important;
			}
			.text-right{
				margin-top: 10px;
			}
		}
	}
	.widget-link{
		li{
			display: inline-block;
			text-transform: uppercase;
			margin-left: 20px;
			@include respond('phone-land'){
				margin-left: 8px;
				margin-right: 7px;
			}
		}
		a{
			color:#fff;
		}
	}
	.widget_services{
		ul{
			li{
				a{
					color: $white;
					display: block;
					@include transitionMedium;
					
				}
			}
		}
		&.style-1{
			ul{
				li{
					a{
						padding: 5px 0 5px 15px;
						&:before{
							content: "\f105";
							font-family: fontawesome;
							left: 0;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							color: var(--primary);
						}
					}
				}
			}
		}
	}
	.wp-block-latest-posts li, 
	.wp-block-categories-list li, 
	.wp-block-archives-list li, 
	.widget_categories ul li, 
	.widget_archive ul li, 
	.widget_meta ul li, 
	.widget_pages ul li, 
	.widget_recent_comments ul li, 
	.widget_nav_menu li, 
	.widget_recent_entries ul li, 
	.widget_services ul li{
		@include transitionMedium;
		left: 0;
		&:hover{
			left:10px;
		}
	}
	.ft-calltoaction{
		display: flex;
		width: 100%;
		justify-content: space-between;
		border-bottom: 2px dashed rgba(255,255,255,0.2);
		margin-bottom: 40px;
		padding-bottom: 40px;
		align-items: center;
		@include respond ('phone-land'){
			padding-bottom: 20px;
			display:block;
		}
		.title{
			color: #fff;
			margin: 0;
			font-size: 45px;
			@include respond('tab-port'){
				font-size: 30px;
			}
			@include respond ('phone-land'){
				font-size: 28px;
				margin-bottom:15px;
			}
		}
	}
}

.widget_getintuch{
	ul{
		li{
			position: relative;
			display: flex;
			align-items: center;
			margin-bottom:20px;
			
			i{
				margin-right: 10px;
				text-align: center;
				color: var(--primary);
				display: block;
				font-size: 28px;
				top: 0;
			}
		}
	}
}

// contact ft
.contact-ft-1{
	text-align:center;
	margin-bottom:40px;
	i{
		color:var(--primary);
		font-size:50px;
		line-height:1;
		margin-bottom: 15px;
		display: inline-block;
	}
	h4{
		color:$white;
		text-decoration: underline;
	}
	h5{
		color:$white;
		font-weight: 400;
	}
}

// list column
.list-column{
	ul{
		display: flex;
		flex-wrap: wrap;
		li{
			flex: 0 0 50%;
			max-width:50%;
		}
	}
}

// fb-link
.fb-link{
	list-style:none;
	margin:0;
	padding:0;
	li{
		display:inline-block;
		
		a{
			color:#fff;
			position:relative;
			&:after{
				content:"";
				background:var(--primary);
				width:5px;
				height:5px;
			}
		}
	}
}


// footer-link
.footer-link{
	
	li{
		display: inline-block;
		font-family: $font-family-title;
		position: relative;
		padding: 0 25px 0 20px;
		line-height: 1.2;
			
		a{
			color: $white;
		}
		&:before{
			content: "";
			height: 5px;
			width: 5px;
			background-color: var(--primary);
			position: absolute;
			top: 50%;
			left: 0;
			border-radius:5px;
			transform: translateY(-50%);
		}
	}
}

// widget_time
.widget_time{
	ul{
		li{
			display: block;
			margin-bottom: 5px;
			label{
				color: rgba(255,255,255,0.7);
				margin-bottom: 0;
				margin-right: 3px;
			}
		}
	}
}