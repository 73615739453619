.icon-bx-wraper{
	&.style-2{
		.icon-content{
			border-radius: 10px;
			border: 3px solid #d0d6db;
			padding: 30px;
			position:relative;
			
			.btn{
				position:absolute;
				right:0;
				bottom:0;
				height:50px;
				width:50px;
				justify-content: center;
				padding: 0;
			}
			
		}
		.number {
			font-size: 80px;
			font-weight: 700;
			color: #fff;
			text-shadow: 2px 0 0 var(--primary), -2px 0 0 var(--primary), 0 2px 0 var(--primary), 0 -2px 0 var(--primary), 1px 1px var(--primary), -1px -1px 0 var(--primary), 1px -1px 0 var(--primary), -1px 1px 0 var(--primary);
			line-height: 0.9;
			
		}
		
	}
	
	// Style 3
	&.style-3{
		padding: 50px 35px;
		background: #fff;
		@include transitionSlow;
		box-shadow:0 10px 60px 0 rgba(0, 0, 0, 0.1);
		overflow: hidden;
		@include transitionMedium;
		.icon-xl{
			position: relative;
			width: auto;
			line-height: 1;
			z-index: 1;
		}
		
		&:after{
		    content: "";
			position: absolute;
			height: 350px;
			width: 100px;
			@include transitionMedium;
			background: var(--rgba-primary-4);
			right: -100px;
			bottom: -20px;
			transform: rotate(15deg);
			box-shadow: 0 -16px 0 30px var(--rgba-primary-1), 0 -16px 0 60px var(--rgba-primary-1), 0 -16px 0 90px var(--rgba-primary-1);
		}
		.title a,
		p{
			@include transitionMedium;
		}
		&:hover{
			background:$secondary;
			transform:translateY(-10px);
			-moz-transform:translateY(-10px);
			-webkit-transform:translateY(-10px);
			-ms-transform:translateY(-10px);
			-o-transform:translateY(-10px);
			.title a{
				color:#fff;
			}
			p{
			    color: #fff;
			}
			&:after{
				background:rgba(255,255,255,0.4);
				box-shadow: 0 -16px 0 30px rgba(255,255,255,0.1), 0 -16px 0 60px rgba(255,255,255,0.1), 0 -16px 0 90px rgba(255,255,255,0.1);
				opacity: 0.5;
				transform: rotate(15deg) scale(1.5);
			}
		}
		@include respond('phone-land'){
			padding: 20px;
			
			.icon-xl{
				margin-bottom: 20px;
				
				i {
					font-size: 60px;
				}
			}
			p{
				margin-bottom: 20px;
			}
		}
	}
	
	// Style 4
	&.style-4{
		padding-right: 80px;
		@include respond('phone'){
			padding-right: 55px;
		}
		.icon-bx{
			margin-right: 25px;
		}
		.btn{
			height: 50px;
			width: 50px;
			line-height: 55px;
			padding: 0;
			text-align: center;
			display: block;
			border-radius: 50%;
			box-shadow: none;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			margin-top: 0;
		
			@include respond('phone'){
				height: 40px;
				width: 40px;
				line-height: 45px;
				i{
					font-size: 14px;
					line-height: 14px;	
				}
			}
		}
	}
	// Style 6
	&.style-6{
		background: #fff;
		padding:40px 40px 70px 40px;
		position:relative;
		z-index:1;
		@include transitionMedium;
		box-shadow: 0 2px 60px 0 rgba(0,0,0,0.1);
		.icon-cell{
			color:var(--primary);
			@include transitionMedium;
		}
		.dz-title{
			position:relative;
			padding-bottom:20px;
			margin-bottom:20px;
			&:after{
				content:"";
				width:100%;
				height:1px;
				background:#000;
				position:absolute;
				bottom:0;
				left:0;
				opacity: 0.1;
			}
		}
		
		.btn{
			padding: 0;
			width: 60px;
			height: 60px;
			text-align: center;
			position: absolute;
			bottom: 0;
			left: 40px;
			justify-content: center;
			i{
				font-size:24px;
			}
		}
		&:after{
			content:"";
			position:absolute;
			width:100%;
			height:100%;
			background:#fff;
			left:0;
			top:0;
			z-index: -1;
			@include transitionMedium;
		}
		
		@include respond('phone'){
			padding: 30px 30px 70px 30px;
			.btn{
				left: 30px;
			}
		}
		&:hover{
			box-shadow: 0 2px 60px 0 var(--rgba-primary-4);
			color:#fff;
			.icon-cell{
				color:#fff;
			}
			.dz-title{
				color:#fff;
				&:after{
					background:#fff;
					opacity: 0.3;
				}
			}
			&:after{
				background:var(--rgba-primary-9);
			}
			.btn{
				background:#fff;
				border-color:#fff;
				color:var(--primary);
			}
		}
		.icon-lg{
		    line-height: 1;
			margin-bottom: 25px;
		}
	}
	// Style 7
	&.style-7{
	    display: flex;
		align-items: center;
		.icon-cell{
			i{
				font-size: 40px;
			}
		}
		p{
			font-size: 18px;
			font-weight: 500;
		}
	}
	
	
	// Style 8
	&.style-8{
		display: flex;
		align-items: center;
		padding: 20px 30px;
		box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
		overflow:hidden;
		@include transitionMedium;
		p{
			font-size: 18px;
		}
		&:after{
			content: attr(data-name);
			position: absolute;
			font-size: 80px;
			right: 10px;
			bottom: 5px;
			line-height: 1;
			font-weight: 700;
			color: #000;
			opacity: .05;
			@include transitionMedium;
		}
		&:hover{
			box-shadow: 0 5px 60px 0 rgba(0, 0, 0, 0.15);
			transform:translateY(-10px);
			-moz-transform:translateY(-10px);
			-webkit-transform:translateY(-10px);
			-ms-transform:translateY(-10px);
			-o-transform:translateY(-10px);
			&:after{
				transform:scale(2);
				-moz-transform:scale(2);
				-webkit-transform:scale(2);
				-ms-transform:scale(2);
				-o-transform:scale(2);
			}
		}
	}
	
	// Style 9
	&.style-9{
		padding: 25px 30px;
		background: #F4F4F4;
		@include transitionSlow;
		overflow: hidden;
		@include transitionMedium;
		.icon-lg{
			.icon-cell{
				i{
				color:var(--primary);
				}
			}
		}	
		.dz-title{
			@include transitionMedium;
			font-size:18px;
			font-weight: 700;
		}
		p{
			@include transitionMedium;
		}
		&:active,
		&:hover{
			background:var(--primary);
			transform:translateY(-10px);
			-moz-transform:translateY(-10px);
			-webkit-transform:translateY(-10px);
			-ms-transform:translateY(-10px);
			-o-transform:translateY(-10px);
			.dz-title {
				color:#fff;
			}
			p{
			    color: #fff;
			}
			.icon-lg{
				.icon-cell{
					i{
					color:#fff;

					}
				}
			}
			&:after{
				background:rgba(255,255,255,0.4);
				box-shadow: 0 -16px 0 30px rgba(255,255,255,0.1), 0 -16px 0 60px rgba(255,255,255,0.1), 0 -16px 0 90px rgba(255,255,255,0.1);
				opacity: 0.5;
				transform: rotate(15deg) scale(1.5);
			}
		}
	}
	
	
}