/* Error Page */
.error-page{
	padding: 30px 0 50px;
	
	.dlab_error{
		font-size: 180px;
		font-weight: 800;
		line-height: 1;
		margin: auto;
		color: var(--primary);
		font-family:var(--font-family-title);
	}
	.error-head {
		font-size: 32px;
		margin: 15px 0 25px;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
	@include respond('phone'){
		padding: 0 0 30px;
		
		.dlab_error{
			font-size: 120px;
			line-height: 120px;
		}
		.error-head{
			font-size: 24px;
			line-height: 34px;
		}
	}
}