// PRICING TABLE
.pricingtable-inner {
    text-align: center;
}
.pricingtable-features {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
		padding: 12px;
		i{
			margin: 0 3px;
		}
	}
	
}
.no-col-gap .pricingtable-wrapper {
    margin-left: -1px;
}

// Table =======
.table-option{
	border: 2px solid #adadad;
	border-radius: 6px;
	padding:50px 0px 30px 0;
	
	.pricingtable-footer{
		border:0;
		background-color: transparent;
	}
	.pricingtable-features{
		border:0;
		background-color: transparent;
		li{
			border:0;
			background-color: transparent;
			color:#919191;
			font-size:18px;
		}
	}
	.pricingtable-title{
		border:0;
		background-color: transparent;
		padding: 0;
		
		*{
			color:#000;
		}
		h2{
			font-size: 28px;
			line-height: 36px;
		}
	}
	.pricingtable-price{
		padding: 0;
		border:0;
		background-color: transparent;
	}
	.pricingtable-bx {
		color: #000;
		font-size: 63px;
		font-weight: 800;
		
		strong, sup{
			font-size: 30px;
			font-weight: 700;
		}
	}
	&:hover,
	&.active{
		background-color:#eeeeee;
		border: 2px solid #eeeeee;
	}
	&.dark{
		@include transitionMedium;
		
		&:hover, 
		&.active {
			background-color: #404040;
			border: 2px solid #404040;
			box-shadow: 0 0 10px 5px rgba(0,0,0,0.3);
			transition: all 0.5s;
			.pricingtable-features li,
			.pricingtable-title h2,
			.pricingtable-price .pricingtable-bx{
				color: #fff;
				@include transitionMedium;
			}
			.pricingtable-footer .btn.black{
				background-color: #fff;
				border-color: rgba(0,0,0,0);
				color: #000;
			}
		}
	}
}

// pricingtable-toggle
.pricingtable-toggle{
	display: flex;
    margin: auto;
    justify-content: center;
    padding: 0;
    margin-bottom: 10px;
	span{
	    font-size: 18px;
		margin-top: 5px;
		font-weight: 500;
	}
	.custom-control-label{
		margin: 0 35px 0 50px;
	}
}