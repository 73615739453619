// video-bx
.video-bx{
	position: relative;
	&.style-1{
		.video-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			.popup-youtube{
				height: 100px;
				width: 100px;
				line-height: 100px;
				text-align: center;
				background-color: var(--primary);
				display: block;
				color: #fff;
				font-size: 42px;
				margin: 0 auto;
				transition: all 0.5s;
				box-shadow: 0 0 0 20px var(--rgba-primary-5);
			}
			@include respond('phone'){
				.popup-youtube{
					height: 70px;
					width: 70px;
					line-height: 70px;
					font-size: 28px;
					box-shadow: 0 0 0 15px var(--rgba-primary-5);
				}
			}
		}
	}
	
	&.style-2{
		.video-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			.popup-youtube{
				height: 100px;
				width: 100px;
				line-height: 100px;
				text-align: center;
				border-radius: 100px;
				background-color: $white;
				display: block;
				color: var(--primary);
				font-size: 24px;
				margin: 0 auto;
				transition: all 0.5s;
				box-shadow: 0 0 0 8px rgba(#fff,0.5);
				@include respond('phone'){
					height: 50px;
					width: 50px;
					line-height: 50px;
					font-size: 14px;
				}
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					border: 1px solid #fff;
					-webkit-border-radius: 50%;
					-khtml-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					-o-border-radius: 50%;
					border-radius: 100%;
					animation: animationSignal1;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal1;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
				&:after{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					border: 1px solid #fff;
					-webkit-border-radius: 100%;
					-khtml-border-radius: 100%;
					-moz-border-radius: 100%;
					-ms-border-radius: 100%;
					-o-border-radius: 100%;
					border-radius: 100%;
					animation: animationSignal2;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal2;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
			}
		}
	}
	
	&.style-3{
		.dz-content-inner{
			padding:0;
		}
		.video-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			.popup-youtube{
				height: 80px;
				width: 80px;
				line-height: 80px;
				text-align: center;
				border-radius: 100px;
				background-color: var(--secondary);
				display: block;
				color: #fff;
				font-size: 24px;
				margin: 0 auto;
				transition: all 0.5s;
				@include respond('phone'){
					height: 50px;
					width: 50px;
					line-height: 50px;
					font-size: 14px;
				}
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					background: var(--secondary);
					-webkit-border-radius: 50%;
					-khtml-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					-o-border-radius: 50%;
					border-radius: 100%;
					animation: animationSignal1;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal1;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
				&:after{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					border: 1px solid #fff;
					background: var(--secondary);
					-webkit-border-radius: 100%;
					-khtml-border-radius: 100%;
					-moz-border-radius: 100%;
					-ms-border-radius: 100%;
					-o-border-radius: 100%;
					border-radius: 100%;
					animation: animationSignal2;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal2;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
			}
		}
	}
	
	
	
	
	
}
@keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}