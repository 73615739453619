.dz-coming-soon {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
	padding-left:90px;
    display: flex;	
	@include respond('tab-port'){
		flex-wrap: wrap;
	}
	@include respond('phone'){
		padding-left: 50px;
	}
	.dz-title{
		font-size: 60px;
		line-height: 1.2;
		color: #000;
		font-family: var(--font-family-title);
		margin-bottom: 20px;
		@include respond('laptop'){
			font-size: 50px;
			line-height: 60px;
		}
		@include respond('tab-land'){
			font-size: 40px;
			line-height: 50px;
		}
		@include respond('phone'){
			font-size: 28px;
			line-height: 35px;
			margin-bottom: 15px;	
		}
	}
	.dz-coming-bx{
		padding-left: 80px;
		padding-right: 80px;
		flex: 0 0 50%;
		max-width:50%;
		align-items: center;
		display: flex;
		@include respond('laptop'){
			padding-left: 50px;
			padding-right: 50px;
		}
		@include respond('tab-port'){
			flex: 0 0 100%;
			max-width: 100%;
			align-items: center;
			display: flex;
			padding: 60px 20px;
			text-align: center;
			justify-content: center;
		}
		@include respond('phone'){
			padding: 20px 10px;	
		}
	}
	@include respond('tab-land'){
		.btn{
			font-size: 13px;
			padding: 15px 20px;
		}
	}
	@include respond('phone'){
		.btn{
			font-size: 12px;
			padding: 12px 18px;
			letter-spacing: 1px;
		}
	}
	.slider-box{
		flex: 0 0 50%;
		max-width:50%;
		@include respond('tab-port'){
			flex: 0 0 100%;
			max-width: 100%;
		}
		img{
			width:100%;
			height:100%;
			object-fit:cover;
		}
	}
}
.countdown{
	margin-bottom: 40px;
	@include respond('phone'){
		margin-bottom: 20px;
	}
	.date{
		color: #000;
		padding-right: 30px;
		display:inline-block;
		@include respond('tab-land'){
			padding-right: 20px;
		}
		@include respond('tab-land'){
			padding-right: 10px;
		}
		@include respond('phone'){
			padding-right: 5px;
			padding-left: 5px;	
		}
		.time{
			color: var(--primary);
			font-family: var(--font-family-title);
			font-weight: 700;
			letter-spacing: 4px;
			font-size: 90px;
			line-height: 90px;
			margin-bottom: 5px;	
			@include respond('laptop'){
				font-size: 70px;
				line-height: 70px;
			}
			@include respond('tab-land'){
				font-size: 50px;
				line-height: 50px;
			}
			@include respond('phone'){
				font-size: 40px;
				line-height: 40px;
				font-weight: 700;	
			}
		}
		span {
			display: block;
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 1px;
			@include respond('tab-land'){
				font-size: 14px;
			}
			@include respond('phone'){
				font-size: 12px;
			}
		}
	}
}
.sidenav-menu{
    position: fixed;
    left: 0;
    height: 100vh;
    width: 90px;
    border-right: 1px solid rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 20px;
    justify-content: space-between;
	@include respond('phone'){
	    width: 50px;	
		padding: 10px 5px 20px 5px;	
	}
	.dz-social-icon{
		margin:0;
		 li a{
			color: #000;
			text-transform: capitalize;
			font-weight: 400;
			writing-mode: vertical-rl;
			padding: 10px 10px;
			margin: 0;
			display: block;
			@include respond('tab-port'){
				padding: 4px 8px;
				font-size: 14px;
			}
			
		}
	}
}

.inquiry-modal{
	padding: 0 15px;
	
	.modal-dialog {
		max-width: 700px;
		display: flex;
		min-height: auto;
		background-color: #fff;
		align-items: stretch;
		border-radius: var(--border-radius-base);
		overflow: hidden;
		
		@include respond('phone'){
			display: block;
		}
	}
	.inquiry-adv{
		flex: 0 0 50%;
		max-width: 50%;
		
		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
		@include respond('phone'){
			display: none;
		}
	}
	.modal-content{
		border: 0;
		border-radius: 0;
		background: #fff;
		padding: 0;
		flex-direction: unset;
		flex: unset;
		max-width: unset;
		.contact-modal{
			padding: 30px;
		}
		.modal-body {
			padding: 0;
		}
		@include respond('phone-land'){
			padding: 25px;
		}
		@include respond('phone'){
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.modal-header {
		border: 0;
		padding: 0;
		display: block;
		text-align: center;
		
		.modal-title {
			font-size: 24px;
			margin-bottom: 25px;
			line-height: 1.3;
			font-family: var(--font-family-title);
			text-align: center;
			width: 100%;
		}
		i{
			color: var(--primary);
			font-size: 40px;
			line-height: 1;
			display: inline-block;
		}
	}
	.btn-close {
		margin: 0;
		position: absolute;
		right: 5px;
		top: 0px;
		color: #000;
		font-weight: 100;
		text-shadow: none;
		opacity: 1;
		font-size: 40px;
		padding: 0;
		height: 40px;
		line-height: 40px;
		width: 40px;
		border: 0;
		background: transparent;
	}
}