.under-construct{
	//background-image:url(../images/background/under-construct.jpg);
	//background-size:cover;
	width:100%;
	height:100vh;
	position:relative;
	padding:80px;
	background-color:#f2f2f4;
	//background-position:center;
	z-index:1;
	overflow: hidden;
	
	
	&:after{
		content:"";	
		background-image:url(../images/background/under-construct.jpg);
		background-size:cover;
		width:100%;
		height:100%;
		position:relative;
		position:absolute;
		top:0;
		left:0;
		background-position:center;
		z-index:-1;
		@include respond('phone'){
			opacity:0.15;
		}
	}
	@include respond('wide-desktop'){
		padding:50px;
	}
	@include respond('phone-land'){
		padding:20px;
	}
	.logo-header{
		height:auto;
		width: 100%;
		a{
			display:inline-block;
			width:180px;
		}
	}
	.dz-content{
		margin-top: auto;
		.dz-title{
			font-size:90px;
			line-height:1.2;
			margin-bottom:10px;
			font-family:var(--font-family-title);
			@include respond('wide-desktop'){
				font-size:70px;
			}
			@include respond('tab-land'){
				font-size:60px;
			}
			@include respond('phone'){
				font-size:32px;
			}
		}
		p{
			font-size:35px;
			font-weight:400;
			margin-bottom:0;
			line-height: 1.2;
			color:#333;
			@include respond('wide-desktop'){
				font-size:28px;
			}
			@include respond('tab-land'){
				font-size:24px;
			}
			@include respond('phone'){
				font-size:16px;
				font-weight:500;
			}
		}
	}
	.inner-box{
		height: 100%;
		display: flex;
		width: 50%;
		flex-wrap: wrap;
		@include respond('tab-land'){
			width: 100%;
		}
	}
}