* {
    outline: none;
    padding: 0;

    &::after {
        margin : 0;
        padding: 0;
    }

    &::before {
        margin : 0;
        padding: 0;
    }
}

body {
    line-height: 1.6;
	color:$body-color;
	font-size: 15px;
	font-family: var(--font-family-base);
	background-color: #e9e9e9;
	
    &.fixed{
        overflow: hidden;
        position: fixed;
    }
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a{
	color: var(--title);
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	font-family:var(--font-family-base);
	font-weight:$headings-font-weight;
	color:var(--title);
}

h1,
.h1{
	line-height:1.3;
	@include respond('tab-port'){
		font-size: 2rem;
	}
	@include respond('phone-land'){
		font-size: 1.875rem;
	}
}
h2,
.h2{
	line-height:1.4;
	@include respond('tab-port'){
		font-size: 1.75rem;
	}
	@include respond('phone-land'){
		font-size: 1.5rem;
	}
}
h3,
.h3{
	line-height:1.4;
	@include respond('phone-land'){
		font-size: 1.375rem;
	}
}
h4,
.h4{
	line-height:1.4;
	@include respond('phone-land'){
		font-size: 1.25rem;
	}
}
h5,
.h5{
	line-height:1.35;
	@include respond('phone-land'){
		font-size: 1.125rem;
	}
}
h6,
.h6{
	line-height:1.5;
	@include respond('phone-land'){
		font-size: 1rem;
	}
}

ul {
    padding: 0;
    margin : 0;
}
img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
li {
    list-style: none;
}

a {
    text-decoration: none;
    outline        : none;
	color          : var(--primary);	
	@include transitionMedium;	
    &:hover,
    &:focus,
    &.active {
        text-decoration: none;
        outline        : none;
		color:var(--primary-hover);
    }
}
// COLOR
.text-primary{
	color:var(--primary) !important; 	
}
.bg-primary {
    background-color: var(--primary) !important;
}
b,
strong,
.strong {
    font-weight: 500;
    color      :var(--title);
}

.h-0 {
    height: 0;
}

#main-wrapper {
    opacity       : 0;
    transition    : all 0.25s ease-in;
    overflow      : hidden;
    position      : relative;
    z-index       : 1;
    margin-top    : 60px;

    &.show {
        opacity: 1;
    }
}

.content-body {
    margin-left  : 65px;
    margin-top   : 18px;
    margin-right : 6px;
    margin-bottom: 45px;
}

.container-fluid{
	padding-left:80px;
	padding-right:80px;
	
	@include respond('wide-desktop'){
		padding-left:40px;
		padding-right:40px;
	}
	@include respond('tab-land'){
		padding-left:20px;
		padding-right:20px;
	}
	@include respond('phone'){
		padding-left:15px;
		padding-right:15px;
	}
}
.bg-gray{
	background-color: #f8f8f8;
}

// Selection
::selection {
	color: $white;
	background:var(--primary);
}

.row.sp60,
.sp60{
	margin-left:-30px;
	margin-right:-30px;
	[class*="col-"]{
		padding-left:30px;
		padding-right:30px;
	}
}
.row.sp40,
.sp40{
	margin-left:-20px;
	margin-right:-20px;
	[class*="col-"]{
		padding-left:20px;
		padding-right:20px;
	}
}
.row.sp20,
.sp20{
	margin-left:-10px;
	margin-right:-10px;
	[class*="col-"]{
		padding-left:10px;
		padding-right:10px;
	}
}
.row.sp16,
.sp16{
	margin-left:-8px;
	margin-right:-8px;
	[class*="col-"]{
		padding-left:8px;
		padding-right:8px;
	}
}
.row.sp10,
.sp10{
	margin-left:-5px;
	margin-right:-5px;
	[class*="col-"]{
		padding-left:5px;
		padding-right:5px;
	}
}
.row.sp4,
.sp4{
	margin-left:-2px;
	margin-right:-2px;
	[class*="col-"]{
		padding-left:2px;
		padding-right:2px;
	}
}
.row.spno,
.spno{
	margin-left:0;
	margin-right:0;
	[class*="col-"]{
		padding-left:0;
		padding-right:0;
	}
}

/* Flaticon */
[class*="flaticon-"]{
	font-family: "Flaticon";
}
@include respond('tab-port'){
	.dz-order-1 {
		order: 1;
		margin-top: 20px;
	}
}
// Pointer
#pointer-dot {
    top:-4px;
    left:-4px;
    width: 12px;
    height: 12px;
    position: fixed;
    border-radius: 50px;
    z-index: 999998;
    pointer-events: none;
    transition: border-color 0.5s;
    background: var(--primary);
	box-shadow: 0 0 0 2px rgba(255,255,255,0.2);
	transition: all 0.1s ease 0s;
}
#pointer-ring {
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    display:block;
    border: 1px solid var(--primary) !important;
    position: fixed;
    border-radius: 100px;
    z-index: 999999;
    pointer-events: none;
	transition: width 0.3s, height 0.3s, left 0.3s, left 0.3s;
	display:none;
}
#pointer-dot.active {
    opacity:0.3;
}

// Section Specing
.content-inner{
	padding-top:120px;
	padding-bottom:90px;
	@include respond('tab-port'){
		padding-top:50px;
		padding-bottom:20px;
	}
}
.content-inner-1{
	padding-top:120px;
	padding-bottom:120px;
	@include respond('tab-port'){
		padding-top:50px;
		padding-bottom:50px;
	}
}
.content-inner-2{
	padding-top:120px;
	padding-bottom:0;
	@include respond('tab-port'){
		padding-top:50px;
	}
}
.content-inner-3{
	padding-top: 60px;
	padding-bottom: 30px;
	@include respond('tab-port'){
		padding-top:50px;
		padding-bottom: 20px;
	}
}
.content-inner-4{
	padding-top: 150px;
	padding-bottom: 150px;
	@include respond('tab-port'){
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
.mfp-bg {
    z-index: 99999;
}
.mfp-container{
	z-index: 999999;
}
.mfp-wrap{
    z-index: 999999;
}

.bg-light {
    background-color: var(--rgba-primary-1) !important;
}