.dz-card{
	&.style-1{
		overflow: hidden;
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0,  0.1);
		background:#fff;
		@include transitionMedium;
		
		.dz-media{
		
		}
		.dz-meta{
			ul{
				line-height: 1;
				li{
					color: #666;
					font-family: var(--font-family-title);
					font-size: 14px;
					position: relative;
					font-weight: 500;
					
					&.post-date{
						position: absolute;
						left: 20px;
						bottom: 100%;
						background: #fff;
						width: 70px;
						padding: 10px 5px;
						margin: 0;
						text-align: center;
						margin-bottom: 20px;
						
						strong{
							color: var(--primary);
							display: block;
							font-size: 38px;
							font-weight: 800;
							line-height: 1;
							margin-bottom: 3px;
						}
						span{
							display: block;
							color: var(--title);
							text-transform: uppercase;
							font-size: 12px;
							font-weight: 600;
						}
						@include respond('phone-land'){
							left: 5px;
							width: 60px;
							padding: 5px 5px 8px;
							margin-bottom: 5px;
							
							strong{
								font-size: 30px;
								margin-bottom: 3px;
							}
						}
					}
					&.post-category{
						a{
							border-bottom: 1px dashed;
							padding-bottom: 1px;
						}
					}
				}	
			}	
		}
		.dz-info{
			position:relative;	
			z-index: 2;
		}
		.icon-btn{
			position: absolute;
			right: 0;
			bottom: 0;
			width: 45px;
			height: 45px;
			padding: 0 0 0 4px;
			text-align: center;
			justify-content: center;
		}
		&.overlay-post{
			.dz-info{
				position: absolute;
				bottom: 0;
				left: 0;
				height: 100%;
				display: inline-flex;
				flex-wrap: wrap;
				flex-direction: column;
				justify-content: flex-end;
				
				.dz-meta ul li{
					color:#fff;
					
					&.post-date{
					    bottom: auto;
					    top: 20px;
						
						@include respond('phone-land'){
							top: 5px;
						}
					}
				}
				.dz-title a{
					color:#fff;
				}
			}
			.dz-media{
				height: 100%;
				
				img{
					height:100%;
					object-fit: cover;
				}
			}
			&:after{
				content: "";
				height: 80%;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				background:linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			}
		}
	}
	&.style-2{
		overflow: hidden;
		position:relative;
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0,  0.1);
		z-index:1;
		@include transitionMedium;
		
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0;
			background:var(--primary);
			z-index:0;
			@include transitionMedium;
		}
		.dz-meta{ 
			margin:0;
			ul{
				li{
				position: absolute;
				top: 0;
				left: 0;
				width:60px;
				height:60px;
				line-height:60px;
				text-align:center;
				color: #fff;
				background: var(--primary);
				font-weight: 700;
				font-size: 30px;
				z-index:1;
					@include transitionMedium;
					
				}
			}
		}
		.dz-title{
			margin-bottom: 0;
		}
		.dz-info{
			position: absolute;
			bottom: -50px;
			left: 0;
			width: 100%;
			z-index: 9;
			text-align: center;
			@include transitionMedium;
			
			.bottom-contact{
				@include transitionMedium;
				margin-bottom: 20px;
				
				.dz-title{
					a{
						font-weight: 700;
						font-size: 24px;
						color:#fff;
					}
				}
				.text{
					font-weight: 500;
					color:#fff;
					margin:0;
				}
			}
			.read-more{
				transform: translateY(50px);
				@include transitionMedium;
				opacity: 0;
	
				a{
					padding:14px 24px;
					background:rgba(50, 50, 50, 1);
					box-shadow:none;
					border:none;
					
					span{
						font-size:15px;
						font-weight:500px;
						i{
							margin-left:10px;
							font-size:13px;
						}
					}
				}
			}
		}
		&:hover{
			&:after{
				height: 100%;
			}
			.read-more{
				transform: translateY(0);
				opacity:1;
			}
			.dz-info{
				bottom: 0;
			}
			.dz-meta{ 
				ul{
					li{
						background:rgba(50, 50, 50, 1);
					}
				}
			}
		}
	}
	
	&.style-3{
		overflow: hidden;
		@include transitionMedium;
		.dz-info{
			padding:30px 0;
			.dz-meta{
				ul{
					display: flex;
					justify-content: space-between;
					align-items: center;
					li{
						span{
							font-weight: 500;
							font-size: 14px;
							svg{
								margin-right: 5px;
								path{
									stroke:var(--primary);
								}
							}
						}
					}
					.post-author{
						img{
							width:30px;
							height:30px;
							border-radius:50%;
						}
					}
				}
			}
			.dz-title{
				margin-bottom: 5px;
			}
			.text{
				font-weight: 400;
				font-size: 16px;
			}
			.read-more{
				.btn{
					padding: 14px 30px;
					font-size: 16px;
				}
			}
		}
	}
	
}


.post-swiper{
	.prev-post-swiper-btn,
	.next-post-swiper-btn{
		position:absolute;
		top:50%;
		width:45px;
		height:45px;
		line-height:45px;
		text-align:center;
		background:var(--primary);
		color:#fff;
		font-size:18px;
		transform:translateY(-50%);
		z-index:1;
		
		@include respond('phone-land'){
			width: 35px;
			height: 35px;
			line-height: 35px;
			font-size: 15px;
			transform: translateY(-70%);
		}
	}
	.prev-post-swiper-btn{
		left:0;
	}
	.next-post-swiper-btn{
		right:0;
	}
}

