/* Silder One */
.silder-one{
	position: relative;
	
	.silder-content{
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 80px;
		height: 100%;
		display: flex;
		align-items: center;
		width:100%;
		@include respond('tab-port'){
			padding: 40px;
		}
		@include respond('phone'){
			padding: 30px;
		}
		.title{
			font-size: 160px;
			line-height: 1;
			margin-bottom: 0;
			color: #fff;
			font-weight: 700;
			span{
				font-weight: 300;
			}
			@include respond('wide-desktop'){
				font-size: 120px;
			}
			@include respond('laptop'){
				font-size: 100px;
			}
			@include respond('phone-land'){
				font-size: 60px;
				line-height:1.2;
			}
			@include respond('phone'){
				font-size: 45px;
				line-height:1.2;
			}
		}
		.sub-title{
			font-weight: 600;
			margin-bottom: 0px;
			color: #fff;
			letter-spacing: 2px;
		}
		.title-small{
			margin-bottom: 40px;
			font-size: 100px;
			line-height: 1;
			color: #fff;
			font-weight: 400;
			@include respond('wide-desktop'){
				font-size: 70px;
			}
			@include respond('laptop'){
				font-size: 50px;
				margin-bottom: 20px;
			}
			@include respond('phone-land'){
				font-size: 35px;
				margin-bottom: 20px;
				line-height:1.2;
			}
			@include respond('phone'){
				font-size: 24px;
				line-height:1.3;
			}
		}
		.overlay-slide{
			position:absolute;
			left:0;
			height:100%;
			width:35%;
			z-index: -1;
			background:var(--rgba-primary-9);
			top:0;
		}
	}
	
	.silder-img{
		overflow:hidden;
		height: calc(100vh - 80px);
		min-height: 600px;
		
		@include respond('phone'){
			min-height: 500px;
		}
		img{
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	
	.slider-one-pagination{
		background-color: #fff;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;
		width: auto;
		display: flex;
		align-items: center;
		padding: 30px 50px;
		@include respond('phone'){
			padding: 15px 35px;
		}
		
		
		.swiper-pagination-bullet {
			width: auto;
			height: auto;
			border-radius: 0;
			background: transparent;
			opacity: .2;
			margin: 0 8px;
			@include transitionMedium;	
			&-active{
				opacity:1;
				color:var(--primary);
			}
		}
		.swiper-pagination{
			margin:0 10px;	
		}
	}
	
}

.clients-logo {
    padding: 0 25px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    transition: all 0.5s;
	opacity: 0.5;
	
	img {
		margin: auto;
		transition: all 0.5s;
	}
	&:hover{
		opacity: 1;
	}
}
.slidearea{
	padding-left:80px;
	position:relative;
	@include respond('phone'){
		padding-left:35px;
	}
	&.bannerside{
		@include respond('tab-port'){
			padding-left:0;
			.side-contact-info{
				display:none;
			}
		}
	}
	.side-contact-info {
		position: absolute;
		left: 0;
		width: 80px;
		height: 100%;
		top:0;
		@include respond('phone'){
			width:35px;
		}
		ul{
			writing-mode: tb-rl;
			display: flex;
			justify-content: center;
			width: 100%;
			height: 100%;
			
			li{
				width: 100%;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 500;
				color: #212529;
				font-family:'Montserrat', sans-serif;
				font-size: 14px;
				padding:20px 0;
				
				&:first-child{
					border-bottom:1px solid #acacac;	
				}
				i{
					color: var(--primary);
					margin: 0px 0px 15px 0px;
					transform: rotate(-90deg);
				}
			}
		}
	}
}
.work-process{
	padding: 30px;
	@include transitionMedium;
	.number{
		position: relative;
		-webkit-text-stroke: 1px #000;
		font-size: 80px;
		color: transparent;
		font-weight: 700;
		line-height: 80px;
		display: inline-block;
		margin-bottom: 15px;
		@include transitionMedium;
		&:before{
			content: "";
			width: 35px;
			height: 35px;
			border-radius: 50px;
			background-color: var(--primary);
			opacity: 0.2;
			position: absolute;
			top: 0;
			left: 0;
			@include transitionMedium;
		}
	}
	&:hover{
		transform:translateY(-10px)	;
		-moz-transform:translateY(-10px)	;
		-webkit-transform:translateY(-10px)	;
		-ms-transform:translateY(-10px)	;
		-o-transform:translateY(-10px)	;
		.number:before{
			width: 55px;
			height: 55px;
		}
	}
}
.subscribe-area{
	padding-top: 400px;
    margin-top: -350px;
	@include respond('phone-land'){
		padding-top: 350px;
	}
}
.subscribe-content{
	border-top:1px dashed rgba(255,255,255,0.7);
	padding-top:80px;
	@include respond('tab-port'){
		padding-top:40px;
		padding-bottom:30px;
	}
}
.dz-subscription{
	.form-control{
		background: #575757;
		border-radius: 50px !important;
		padding: 15px 195px 15px 25px;
		height: 70px;
		color:$white;
		border: 0;
		&::-webkit-input-placeholder {
			color: $white;
		}

		&:-ms-input-placeholder {
			color: $white;
		}

		&::placeholder {
			color: $white;
		}
		@include respond('phone'){
			padding: 15px 75px 15px 25px;
			height: 60px;
		}
	}
	.btn{
		border-radius: 50px !important;
		position: absolute;
		right: 5px;
		top: 5px;
		bottom: 5px;
		z-index: 9;
		
		@include respond('phone'){
			width: 50px;
			justify-content: center;
			height: 50px;
			padding: 0;
			
			span{
				display: none;
			}
			i{
				margin: 0 !important;
			}
		}
	}
}
.twentytwenty-img-area{
	@include respond('tab-port'){
		margin-bottom:30px;
	}
}
.footer-bg{
	height: 100%;
    width: 50vw;
    float: right;
    overflow: hidden;
    border-radius: 0px 0px 30px;
	background-size:cover;
	
}

// Services Sidebar List
.ext-sidebar-menu{
	
	ul{
	
		li{
			border-bottom: 1px dashed #7a7e82;
			position: relative;			
			
			&:before{
				content: none;
			}
			a{
				
				font-size: 24px;
				line-height: 24px;
				color: #212529;
				display: block;
				font-weight: 600;
				position: relative;
				padding: 25px 30px 25px 50px;
				@include transitionMedium;
				@include respond('tab-land'){
					font-size: 18px;
					padding: 15px 20px 15px 40px;
				}
				
				&:after{
					content: "\f067";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					padding: 0;
					position: absolute;
					top: 50%;
					font-size: 14px;
					left: 0;
					width: 40px;
					height: 40px;
					text-align: center;
					border: 1px solid var(--primary);
					color: var(--primary);
					border-radius: 40px;
					line-height: 40px;
					transform: translateY(-50%);
					-moz-transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					-o-transform: translateY(-50%);
					@include transitionMedium;
					@include respond('tab-land'){
						width: 30px;
						height: 30px;
						line-height: 30px;
						font-size: 12px;
					}
				}
			}
			&:hover{				
				a{
					color: var(--primary);
					transform: translateX(10px);
					-moz-transform: translateX(10px);
					-webkit-transform: translateX(10px);
					-ms-transform: translateX(10px);
					-o-transform: translateX(10px);
				}
			}
			&.active{
				a{
					color: var(--primary);
					&:after{
						background: var(--primary);
						color:#fff;
					}
				}
			}
			@include respond('tab-port'){
				a{
					font-size: 18px;
					line-height: 18px;
				}
			}
		}
	}
}

// service-detail
.service-detail{
	.dz-media{
		margin-bottom: 45px;
	}
	@include respond('phone'){
		.dz-media{
			margin-bottom: 30px;
		}	
	}
}
// port-detail
.port-detail{
	.dz-title{
		line-height: 1.3;
		font-weight: 800;	
		font-size:2.5rem;
		@include respond('tab-port'){
			font-size:1.75rem;
		}
	}
	.info-list{
		@include respond('tab-port'){
			margin-top:30px;	
		}
		li{
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			margin-top: 30px;
			@include respond('tab-port'){
				margin-bottom: 15px;
				margin-top: 15px;
			}
			h2{
				margin: 0;
				width: 150px;
				font-weight: 700;
				font-size: 50px;
				line-height: 1;
				@include respond('tab-port'){
					width: 120px;
					font-weight: 700;
					font-size: 40px;
				}
			}
		}
	}
}










